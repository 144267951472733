import { Fragment } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import Body from "./Components/Body";

function App() {
  return (
    <Fragment>
      <NavBar></NavBar>
      <Container>
        <Stack>
          <Box sx={{ height: "15px" }}></Box>
          <Header></Header>
          <Box sx={{ height: "30px" }}></Box>
          <Body></Body>
        </Stack>
      </Container>
    </Fragment>
  );
}

export default App;
