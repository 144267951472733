import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import CardMedia from "@mui/material/CardMedia";

import Link from "@mui/material/Link";

export default function BasicCard({
  title,
  description,
  linkToImage,
  linkToPage,
}) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>

        <Link href={linkToPage}>
          <CardMedia component="img" image={linkToImage} alt={title} />
        </Link>

        <Typography variant="body">{description}</Typography>
      </CardContent>
    </Card>
  );
}
