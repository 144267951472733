import React from "react";

import Grid from "@mui/material/Grid";
import ShowcaseCard from "./ShowcaseCard";

import appData from "../data/appData.json";

const Body = () => {
  const renderDatum = (datum) => {
    const title = datum["title"];
    const description = datum["description"];
    const linkToImage = datum["linkToImage"];
    const linkToPage = datum["linkToPage"];
    return (
      <Grid item xs={4}>
        <ShowcaseCard
          title={title}
          description={description}
          linkToImage={linkToImage}
          linkToPage={linkToPage}
        ></ShowcaseCard>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {appData.map((datum) => {
        return renderDatum(datum);
      })}
    </Grid>
  );
};

export default Body;
