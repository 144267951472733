import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Header() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={1}></Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" align="center">
          A collection of Web Applications created by the{" "}
          <Link href="https://www.umc.edu/mindcenter/MIND-CENTER-Home-page.html">
            {" "}
            MINDSET
          </Link>{" "}
          Core: a team of Data Scientists and Biostatisticians at the MIND
          Center of the{" "}
          <Link href="https://www.umc.edu/">
            University of Mississippi Medical Center
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body" align="center">
          MINDSET: Memory Impairment and Neurodegenerative Dementia Science,
          Evidence, and Technology.
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}

export default Header;
